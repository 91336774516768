.container {
  padding: 0 var(--spacer);
  max-width: 70rem;
  margin: 0 auto;
}
.m-box {
  padding: 2em;
}
.error {
  color: crimson;
}
.text-accent {
  color: var(--clr-accent);
}
.txt-xl, .btn-xl {
  font-size: 125%;
}

.txt-xxl {
  font-size: 3em;
}

.secondary {
  --clr: var(--clr-neutral-700, grey);
  color: var(--clr);

  a:link, a:visited {
    color: var(--clr);
  }
  a:hover {
    color: var(--clr-neutral-700);
  }
}

.split {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap, 1rem);
}
@media (min-width: 48em) {
  .split {
    flex-direction: row;
  }
}
.flow-content > * + * {
  margin-top: var(--spacer);
}
.flow-content--3x > * + *  {
  margin-top: var(--3spacer);
}
.flow-content-horizontal > * + * {
  margin-left: var(--spacer);
}
.center {
  text-align: center;
}
.flex-center {
  display: flex;
  align-items: center;
}
.shadow {
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  border-radius: var(--radius);
}
a {
  color: #2371F4;
}
.my-3 {
  margin-top: var(--3spacer);
  margin-bottom: var(--3spacer);
}
.mb-3 {
  margin-bottom: var(--3spacer);
}
.fw-500 {
  font-weight: 500;
}