.page-footer {
  background-color: var(--clr-neutral-100);
  padding: var(--3spacer) var(--spacer);
  margin-top: var(--3spacer);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;

  .pure-menu-link {
    color: var(--clr-neutral-900);
    padding: 0.5em 0;
  }

  > div {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    gap: var(--spacer);
  }

  > div:last-child {
    img {
      min-height: 1.2em;
    }
  }

  @media (min-width: 48em) {
    --spacer: 1.7rem;
    --padding: calc(var(--spacer) * 2);
    padding: calc(var(--padding) * 1.3) var(--padding);
    > div:last-child {
      text-align: right;
      justify-content: space-between;
    }
  }

  .logo-group {
    font-size: 1.7em;
  }
}
