@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;

  .comment {
    color: var(--clr-neutral-300)
  }
  .keyword {
    color: coral;
  }
  .data {
    color: lightskyblue;
  }
  .func {
    color: plum;
  }
}
pre {
  background-color: var(--clr-neutral-900, black);
  color: white;
  padding: var(--spacer);
  max-width: calc(100vw - 4 * var(--spacer));
  position: relative;

  &[rel]::before {
    color: coral;
    top: var(--spacer);
    right: var(--spacer);
    position: absolute;
    font-weight: bold;
    content: attr(rel);
  }
}
pre code {
  display: block;
  max-width: 100%;
  min-width: 100px;
  padding: 0;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
}
.h1 {
  font-size: 2rem;
}
.h2 {
  font-size: 1.5rem;
}
