#docushareLogo {
  max-height: 3em;
}

@media (min-width: 48em) {
  .mirrored {
    p {
      order: 1;
    }
  }
}

.docushare-features {
  img {
    max-height: 32em;
  }
}