dialog {
  padding-bottom: var(--spacer);

  header {
    align-items: center;
    background-color: var(--clr-neutral-100);
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
}
.dialog-title {
  font-size: 1.2em;
  font-weight: bold;
}
