.pure-form {
  input[type=text], textarea {
    border-radius: var(--radius);
    padding: 0.8rem;
  }
}
.pure-form-stacked {
  input[type=text], label, textarea {
    margin-top: var(--spacer);
  }
}
