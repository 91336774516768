.carousel {
  display: flex;
  justify-content: center;
  gap: .5rem;

  &__control {
    display: flex;
    align-items: center;
    button {
      line-height: 1.5em;
    }
  }
}

.fade-in {
  display: block;
  animation: fadeIn 0.2s ease-in forwards;
}
.fade-out {
  animation: fadeOut 0.2s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(3rem);
    display: none;
  }

  to {
    opacity: 1;
    transform: translate(0);
    display: block;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0);
    display: block;
  }

  to {
    opacity: 0;
    transform: translate(-3rem);
    display: none;
  }
}
