:root {
  --secondary-bg: rgb(66, 184, 221); // light blue
  --secondary-fg: white;

  --clr-accent: rgb(35, 113, 244);
  --clr-danger: crimson;
  --clr-neutral-100: #eeeeee;
  --clr-neutral-300: #cccccc;
  --clr-neutral-500: #999999;
  --clr-neutral-700: #666666;
  --clr-neutral-900: #333333;

  --spacer: 1rem;
  --3spacer: calc(var(--spacer) * 3);
  --radius: 0.8rem;
}
