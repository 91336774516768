@import "styles/resets";
@import "styles/variables";
@import "styles/typography";
@import "styles/buttons";
@import "styles/form";
@import "styles/modal";
@import "styles/utilities";

.error {
  color: var(--clr-danger);
}
