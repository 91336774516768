.btn-secondary {
  color: var(--secondary-fg);
  background: var(--secondary-bg);
}
.btn-xs {
  font-size: 70%;
}
.btn-sm {
  font-size: 85%;
}
.btn-blk {
  display: block;
  width: 100%;
}
.pure-button {
  border-radius: 100vh;
}
.btn--circle {
  border-radius: 50%;
}
