.main-menu {
  box-shadow: 0 4px 8px -2px var(--clr-neutral-100, rgba(0,0,0,0.07));
  justify-content: space-between;
  padding: 0.25em var(--spacer) 0.25em 0;
  margin-bottom: 0.5rem;

  @media (max-width: 48em) {
    .collapsible {
      display: none;
    }
  }

  .logo-group {
    font-size: 125%;
  }
}

.logo-group {
  color: var(--clr-neutral-900);
  img {
    max-height: 1.5em;
    margin-right: 0.2em;
  }
}
.pure-menu-link {
  border-radius: 100vh;
  &.active {
    color: var(--clr-neutral-900, black);
    font-weight: bolder;
  }
  &.pure-button {
    background-color: transparent;
    border: 1px solid;
  }
}
