// em is better than px when users zoom in-out
$mobile-width: 48em;

@mixin mobile { @media (max-width: $mobile-width) {
  @content;
 } 
}

@mixin desktop { @media (min-width: #{$mobile-width}) {
  @content;
 } 
}