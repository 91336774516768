.breadcrumbs {
  margin: 0.5em auto;
  display: flex;
  justify-content: center;
  .crumb {
    color: var(--clr-neutral-500);
    a {
      color: inherit;
      text-decoration: none;
    }
    &:last-child {
      color: var(--clr-accent);
    }
  }
}
.breadcrumbs > * + * {
  --gap: 0.5em;
  margin-left: var(--gap);
  &::before {
    content: '>';
    margin-right: var(--gap);
  }
}
