@import "../../../styles/responsive";

@include desktop {
  .product-summary {
    > div:first-child > div {
      flex-basis: 50%;
    }
  }
}

.product-summary .cta .pure-button {
  min-width: 50%;
}
