@import "../styles/responsive";

.two-cols {
  gap: 2rem;
  @include desktop {
    flex-direction: row;
  }

  .col {
    flex-basis: 50%;
  }
}
